<template>
  <el-dialog title="报警处理"
    :visible.sync="visible"
    width="640px" append-to-body
    custom-class="policy-import-dialog"
    :before-close="()=> $emit('input',false)">

    <!-- 内容区-->
    <div class="mainCls">
      <div class="mainFrom">
        <!-- <div class="main_item"
          v-if="!selects.length">
          <div class="main_item__edit">
            <label>设备名称</label>
            <el-input disabled
              v-model="alarmParams.plate"></el-input>
          </div>

          <div class="main_item__edit">
            <label>所属车组</label>
            <el-input disabled
              v-model="alarmParams.groupName"></el-input>
          </div>

          <div class="main_item__edit">
            <label>报警时间</label>
            <el-input disabled
              v-model="alarmParams.alarmTime"></el-input>
          </div>

          <div class="main_item__edit">
            <label>报警类型</label>
            <el-input disabled
              v-model="alarmParams.alarmTypeCh"></el-input>
          </div>
          <div class="main_item__edit">
            <label>报警来源</label>
            <el-input disabled
              v-model="alarmParams.alarmSourceStr"></el-input>
          </div>

          <div class="main_item__edit">
            <label>报警地址</label>
            <el-input disabled
              type="textarea"
              :rows="2"
              v-model="alarmParams.alarmAddress"></el-input>
          </div>
        </div>

        <div class="main_item"
          v-else>
          <el-table :data="selects"
            height="100%">
            <el-table-column prop="plate"
              label="设备名称"
              width="120"></el-table-column>
            <el-table-column prop="type"
              label="报警类型"
              width="100"></el-table-column>
            <el-table-column prop="timeBegin"
              label="报警时间"
              width="150"></el-table-column>
            <el-table-column prop="address"
              width="230"
              label="报警地址"></el-table-column>
          </el-table>
        </div> -->

        <div class="main_item">
          <div class="main_item__edit">
            <label class="required">处理方式</label>
            <el-select v-model="handleParams.handleType"
              placeholder="请选择"
              style="width:100%">
              <el-option v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </div>
          <div class="main_item__edit"
            v-if="[32, 512].includes(handleParams.handleType)">
            <label class="required">通知电话</label>
            <el-input v-model="handleParams.phone"
              @keyup.native="
              handleParams.phone = handleParams.phone.replace(/[^0-9-]/g, '')
            "></el-input>
          </div>

          <div class="main_item__edit">
            <label class="required">处理人员</label>
            <el-input v-model="handleParams.handler"></el-input>
          </div>
          <div class="main_item__edit">
            <label>处理内容</label>
            <el-input type="textarea"
              :rows="3"
              v-model="handleParams.handleContent"></el-input>
          </div>

          <div class="main_item__edit">
            <label>备注</label>
            <el-input v-model="handleParams.remark"
              type="textarea"
              :rows="2"></el-input>
          </div>
        </div>
      </div>

      <div class="mainTip"
        v-if="selects.length">
        本次将处理
        <span class="color_tip">{{this.selects.length}}</span> 条数据
      </div>
    </div>
    <span slot="footer"
      class="dialog-footer">
      <el-button @click="$emit('input',false)">取 消</el-button>
      <el-button type="primary" @click="submitOk">处 理</el-button>
    </span>
  </el-dialog>
</template>

<script>

/**
 * 报警处理
 */

import Dialog from '@/components/Dialog';
import cmdWsObj, { CmdType } from '@/utils/cmdWebSocket';
import { phoneFun } from '@/utils/index';
import { mapState } from 'vuex';
import dayjs from 'dayjs';

export default {
  name: 'AlarmHandelDialog',
  components: {
    Dialog
  },
  props: {
    /**
     * 控制弹框
     */
    visible: {
      type: Boolean,
      default: false,
    },
    /**
     * 传承参数
     */
    iProp: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      alarmParams: {},
      handleParams: this.initHandleParams(),
      options: [ // 处理类型
        {
          value: 8,
          label: '调度下发--TTS播报'
        },
        {
          value: 32,
          label: '短信通知'
        },
        {
          value: 512,
          label: '语音通知'
        },
        {
          value: 128,
          label: "测试"
        },
        {
          value: 256,
          label: "误报"
        },
        {
          value: 0,
          label: "忽略报警"
        }
      ],

      selects: []
    };
  },

  watch: {
    visible: {
      handler(val) {
        this.alarmParams = this.iProp.alarmParmas;
        this.handleParams = this.initHandleParams();
        this.selects = [];
        if (val) {
          this.alarmParams = this.iProp.alarmParmas;
          this.selects = this.iProp.selects;
        }

      }
    }
  },
  computed: {
    ...mapState(['userInfo'])
  },
  methods: {
    // 初始化处理数据
    initHandleParams() {
      return {
        handleType: 256, // 处理类型
        phone: '', // 通知电话
        handler: '', // 处理人员 
        handleContent: '', // 处理内容
        remark: '', // 备注
      };
    },

    // 数据校验
    validate() {
      const { phone, handleType, handler } = this.handleParams;

      if ([32, 512].includes(handleType) && !phoneFun(phone)) {
        this.$message.warning("请输入正确的通知电话");
        return false;
      }

      if (handleType === '') {
        this.$message.warning("请选择处理类型");
        return false;
      }

      if (!handler) {
        this.$message.warning('请输入处理人员');
        return false;
      }

      return true;
    },

    // 点击下发处理命令
    async submitOk() {

      if (!this.validate()) return; // 校验

      if (!cmdWsObj.request) throw new Error('命令链路未连接');
      // 判断报警类型
      const { type } = this.iProp;
      const { phone, handleType, handleContent, handler, remark } = this.handleParams;
      const { alarmId, groupId, vehicleId, alarmTypeEn, plate, alarmAddress, alarmTime, alarmType, alarmNameEnglish } = this.alarmParams;

      // 是否是ADAS报警
      const isAdasAlarm = type === 1;
      // 是否是常规报警
      const isGeneralAlarm = type === 0;

      const extend = {
        msgSendAddr: phone,
        plate,
        alarmAddress,
        alarmTime: dayjs(alarmTime).valueOf(),
      };

      // 短信模板
      let handleTemplate = '';
      if ([32].includes(handleType)) {
        handleTemplate = 'SMS_232176144';
      }

      const cmdParamsBoday = {
        isAuto: 0, // 处理方式 0： 人工处理
        handleType: handleType,
        handleTemplate, // 报警处理模板, 短信有效
        loginName: this.userInfo.name,
        userId: this.userInfo.userId,
        handleContent: handleContent,
        handler: handler,
        remark: remark,
      };
      let cmdParams = [
        {
          alarmId: alarmId,
          groupId: groupId,
          vehicleId: vehicleId,
         //  alarmType: isAdasAlarm ? alarmTypeEn : alarmType,
         alarmType: alarmNameEnglish ? alarmNameEnglish : isAdasAlarm ? alarmTypeEn : alarmType,
          extend,
        }
      ];

      const { selects } = this;
      if (selects.length) {
        cmdParams = selects.map(item => {
          return {
            alarmId: item.alarmId,
            groupId: item.groupId,
            vehicleId: item.vehicleId,
            // alarmType: isAdasAlarm ? item.alarmTypeEn : item.alarmType,
            alarmType: item.alarmNameEnglish ? item.alarmNameEnglish : isAdasAlarm ? item.alarmTypeEn : item.alarmType,
            extend: {
              msgSendAddr: phone,
              plate: item.plate,
              alarmAddress: item.address,
              alarmTime: dayjs(item.alarmTime).valueOf(),
            }
          };
        });
      }

      // 组装下发消息体
      const cmdData = {
        ...cmdParamsBoday,
        param: cmdParams
      };



      const cmdtype = isAdasAlarm ? CmdType.adasAlarmProcess : CmdType.generalAlarmProcess;

      // TODO: 是否需要等待回复
      cmdWsObj.request.sendCmdAsync(cmdtype, cmdData);

      this.$message.info('指令已下发');

      this.$emit('on-cmdSend', type);
      this.close();

      return true;
    },

    close() {
      this.$emit('input', false);
    }
  }

}
</script>

<style lang="scss" scoped>
.mainCls {
  width: 100%;
  height: 100%;
}
.policy-import-dialog {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  .el-dialog__header {
    font-size: 18px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    background: #4278c9;
      color: #fff;
    .el-dialog__title {
      color: #fff;
    }
    .el-dialog__headerbtn {
      top: 11px;
      .el-dialog__close {
        color: #fff;
        font-weight: bold;
      }
    }
  }
  .el-dialog__body {
    padding: 24px;
    padding-top: 14px;
  }
}
.mainTip {
  width: 90%;
  height: 35px;
  margin: 0 auto;
  color: #000000;
  line-height: 35px;
  background: #fff8ef;
  border: 1px solid #ffc091;
  padding: 0 20px;
}
.color_tip {
  color: #ffc091;
  font-weight: 600;
}

.mainFrom {
  width: 100%;
  height: 85%;
  display: flex;
  justify-content: center;
}
.main_item {
  width: 100%;
  height: 98%;
  padding: 10px 30px;
  &__edit {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 20px 0;
    & > label {
      font-size: 14px;
      width: 100px;
      text-align: end;
      padding-right: 10px;
      line-height: 30px;
    }
  }
}

.required:before {
  content: '* ';
  color: red;
}
</style>