<template>
  <div class="adas-video-wrap" :src="videoSrc">
    <div class="video-shelter" v-show="!imgShow" @dblclick="$emit('dblclick')">
      <img
        class="play-img"
        :src="playImg"
        @click="playstart"
        @mouseover="onMouseover"
        @mouseout="onMouseout"
      />
    </div>
    <el-dialog
      width="560"
      :visible.sync="imgShow"
      append-to-body :before-close="playend">
      <video
      :id="videoId"
      class="adas-video"
      ref="ddddd"
      autoplay
      controls
      @ended="playend"
      @dblclick="$emit('dblclick')"
    ></video>
    </el-dialog>
    <!-- <video
      :id="videoId"
      class="adas-video"
      ref="ddddd"
      autoplay
      :controls="false"
      @timeupdate="timeupdate"
      @ended="playend"
      @dblclick="$emit('dblclick')"
    ></video> -->

  </div>
</template>
<script>

export default {
  name: "adasVideo",
  props: {
    videoSrc: null,
    autoplay: false,
  },
  data() {
    return {
      ith: null,
      playtime: 0,
      videoId: Math.random().toString(36).substr(2),
      playImg: require("../assets/images/play-2.png"),
      imgShow: false,
    };
  },
  methods: {
    async playstart() {
      // await this.$nextTick();
      this.imgShow = true;
      this.playtime = 0;
      this.$nextTick(()=>{
         const videoElement = document.getElementById(this.videoId);
         if (!videoElement) return;
         videoElement.src = this.videoSrc;
      })
    },
    timeupdate() {
      this.playtime = new Date().getTime();
      this.imgShow = true;
    },
    playend() {
      this.imgShow = false;
      this.playtime = 0;
    },
    onMouseover(e) {
      this.playImg = require("../assets/images/play-2.png");
    },
    onMouseout(e) {
      this.playImg = require("../assets/images/play-2.png");
    },
  },
  watch: {
    playtime: {
      handler: function (val, oldval) {
        clearTimeout(this.ith);
        this.ith = setTimeout(() => {
          if (
            this.playtime > 0 &&
            new Date().getTime() - this.playtime > 1000 &&
            !this.imgShow
          ) {
            this.imgShow = false;
            this.playtime = 0;
          }
        }, 2000);
      },
    },
    videoSrc: {
      immediate: false,
      handler: function (val, oldval) {
        this.imgShow = false;
        if (this.autoplay) this.playstart();
      },
    },
  },
  beforeDestroy() {
    if (!this.imgShow) {
      this.$nextTick(()=>{
        this.$refs.ddddd ? this.$refs.ddddd.pause() : '';
      })
    }
  },
  mounted() {
    this.imgShow = false;
    if (this.autoplay) this.playstart();
  },
};
</script>
<style lang="scss">
.adas-video-wrap {
  width: 100%;
  height: 100%;
  position: relative;
  .video-shelter {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    border-radius: 1px;
    z-index: 1;
    background-color: black;
    .play-img {
      width: 52px;
      height: 52px;
      cursor: pointer;
    }
  }
}
.adas-video {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
</style>
