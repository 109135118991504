<template>
  <el-dialog
    title="报警详情"
    :visible.sync="dialogVisible"
    append-to-body
    custom-class="policy-import-dialog"
    width="640px"
    :before-close="handleClose"
  >
    <div class="statistical-box">
      <div class="statistical-box-title">
        <div class="statistical-box-title-text">
          <span>{{ details.alarmName }}</span>
          <div
            v-if="isHandle"
            :class="{
              'statistical-box-title-status': true,
              'statistical-box-title-status2':
                details.handleState && details.handleState == 1,
            }"
          >
            {{
              details.handleState && details.handleState == 1
                ? "已处理"
                : "未处理"
            }}
          </div>
        </div>
        <div class="statistical-box-title-time">
          {{ details.beginTime || details.timeBegin }}
        </div>
      </div>
      <div class="statistical-box-content">
        <div>设备名称：{{ details.plate }}</div>
        <div>设备种类：{{ details.vehicleClassName }}</div>
        <div>所属车组：{{ details.groupName }}</div>
        <div>驾驶员：{{ details.driverName || "--" }}</div>
        <div>
          告警等级：{{
            details.levelStr ||
            details.levelDesc ||
            `${
              details.level == 1
                ? "低"
                : details.level == 2
                ? "中"
                : details.level == 3
                ? "高"
                : ""
            }` ||
            "--"
          }}
        </div>
        <!-- <div>持续时间：{{ details.duration }}分钟</div> -->
        <div>报警车速：{{ details.speed || "0" }}km/h</div>
        <div style="display: flex; align-items: center">
          报警定位：
          <span>{{ details.address }} </span>
          <AddressText
            :autoScroll="false"
            :lng="details.lon"
            :lat="details.lat"
          />
        </div>
        <div
          v-if="details.handleState && details.handleState == 1 && !isNewHandle"
        >
          处理人：{{ details.handler || "--" }}
        </div>
        <div
          v-if="details.handleState && details.handleState == 1 && isNewHandle"
        >
          处理人：{{ details.userName || "--" }}
        </div>
        <div
          v-if="details.handleState && details.handleState == 1 && isNewHandle"
        >
          处理方式：{{ details.handler || "--" }}
        </div>
        <div v-if="details.handleState && details.handleState == 1">
          处理时间：{{ details.handleTime }}
        </div>
        <div v-if="details.handleState && details.handleState == 1">
          处理内容：{{ details.handleContent || "--" }}
        </div>
      </div>
      <div class="statistical-box-decoration">
        <div class="statistical-box-decoration-top">
          <div class="statistical-box-decoration-top-tab">
            <div
              :class="{
                'statistical-box-decoration-top-tab-current':
                  decorationIndex == 1,
              }"
              @click="decorationIndex = 1"
            >
              附件
            </div>
            <div
              :class="{
                'statistical-box-decoration-top-tab-current':
                  decorationIndex == 2,
              }"
              @click="decorationIndex = 2"
            >
              定位
            </div>
          </div>
          <div
            class="statistical-box-decoration-top-download"
            v-if="details.attachment && details.attachment.length && isDownload"
            @click="$emit('downloadClick')"
          >
            <img src="@/assets/images/download-icon.png" alt="" srcset="" />
            <span>下载</span>
          </div>
        </div>
        <div
          class="statistical-box-decoration-down"
          v-show="decorationIndex == 1"
        >
          <div class="statistical-box-decoration-down-box">
            <el-image
              class="statistical-box-decoration-down-content"
              v-for="(item, index) in details.imgAttachment"
              :key="index"
              :src="item"
              :preview-src-list="details.imgAttachment"
            >
            </el-image>

            <DialogVideo
              class="statistical-box-decoration-down-content"
              :videoSrc="item"
              v-for="(item, index) in details.videoAttachment"
              :key="index + 999"
            ></DialogVideo>
            <!-- <video class="statistical-box-decoration-down-content" controls :src="item" v-for="(item,index) in details.videoAttachment" :key="index + 999"></video> -->
            <img
              src="@/assets/images/zwsj-image.png"
              v-if="
                (!details.imgAttachment || !details.imgAttachment.length) &&
                (!details.videoAttachment || !details.videoAttachment.length)
              "
              style="height: 170px; margin: auto"
              alt=""
              srcset=""
            />
          </div>
        </div>
        <div
          class="statistical-box-decoration-down"
          ref="container"
          v-show="decorationIndex == 2"
        ></div>
      </div>
      <disposeDialog
        v-model="isDispose"
        :equipmentData="equipmentData"
        :alarmData="details"
        :conditionObj="conditionObj"
        equipmentType="9"
        @changeList="$emit('changeList')"
      />
    </div>
    <span slot="footer" class="dialog-footer" v-if="details.handleState != 1">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="$emit('onHandleClick')" v-if="isHandle"
        >处 理</el-button
      >
      <el-dropdown
        @command="handleCommand($event, details)"
        v-if="details.handleState == 0 && isNewHandle"
      >
        <el-button
          size="mini"
          class="polecy-button"
          style="margin-left: 10px"
          type="primary"
          >处理</el-button
        >
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="1" @click.stop=""
            >确认无风险</el-dropdown-item
          >
          <el-dropdown-item command="2" @click.stop="">误报</el-dropdown-item>
          <el-dropdown-item command="3" @click.stop="">风险点</el-dropdown-item>
          <!-- <el-dropdown-item command="4">电话联系</el-dropdown-item> -->
        </el-dropdown-menu>
      </el-dropdown>
    </span>
  </el-dialog>
</template>

<script>
import { downloadEnquiryTemplate, importEnquireArchive } from "@/api/policy";
import DialogVideo from "@/components/DialogVideo";
import AddressText from "@/components/AddressText";
import { riskHandle } from "@/api/SystemData";
import disposeDialog from "@/views/riskControlManagement/components/disposeDialog";
export default {
  props: {
    showDialog: Boolean,
    details: {
      type: Object,
      default: () => {},
    },
    currentVehicle: {
      type: Object,
      default: () => {},
    },
    equipmentData: {
      type: Object,
      default: () => {},
    },
    conditionObj: {
      type: Object,
      default: () => {},
    },
    isDownload: {
      type: Boolean,
      default: true,
    },
    isHandle: {
      type: Boolean,
      default: true,
    },
    isNewHandle: {
      type: Boolean,
      default: true,
    },
  },
  components: { DialogVideo, AddressText, disposeDialog },
  model: {
    prop: "showDialog",
    event: "change",
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit("change", false);
        } else {
          this.decorationIndex = 1;
          this.$emit("change", true);
        }
      },
    },
    showDialog: {
      handler(v) {
        if (v) {
          this.dialogVisible = true;
        } else {
          this.dialogVisible = false;
        }
      },
    },
    decorationIndex: {
      handler(v) {
        if (v == 2) {
          this.init();
        }
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
      decorationIndex: 1,
      marker: null,
      mapall: null,
      fileList: [],
      alarmData: null, //
      isDispose: false,
    };
  },
  methods: {
    handleClose(done) {
      // this.$confirm("确认关闭？")
      //   .then((_) => {
      done();
      // })
      // .catch((_) => {});
    },
    init() {
      const that = this;
      let Lng = "";
      let Lat = "";
      if (this.details.lonLat) {
        let lonLat = this.details.lonLat.split(",");
        Lng = lonLat[0];
        Lat = lonLat[1];
      }
      if (that.mapall) {
        that.mapall.setCenter([Lng, Lat]);
        that.mapall.setZoom(15);
        that.mapall.remove(this.marker);
        // 创建一个 Marker 实例：
        this.marker = new AMap.Marker({
          position: new AMap.LngLat(Lng, Lat), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
          title: this.details.alarmName,
          icon: "//yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/positioning-marker.png", // 添加 Icon 图标 URL
          offset: new AMap.Pixel(-20, -40),
        });

        // 将创建的点标记添加到已有的地图实例：
        that.mapall.add(this.marker);
      } else {
        var map = new AMap.Map(that.$refs.container, {
          resizeEnable: true,
          center: [Lng, Lat],
          zoom: 15,
        });
        that.mapall = map;
        // 创建一个 Marker 实例：
        this.marker = new AMap.Marker({
          position: new AMap.LngLat(Lng, Lat), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
          title: this.details.alarmName,
          icon: "//yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/positioning-marker.png", // 添加 Icon 图标 URL
          offset: new AMap.Pixel(-20, -40),
        });

        // 将创建的点标记添加到已有的地图实例：
        that.mapall.add(this.marker);
      }
    },
    handleCommand(e, data, type) {
      const h = this.$createElement;
      const that = this;
      switch (e) {
        case "1":
          riskHandle({
            vehicleId: data.vehicleId, //车辆id
            plate: data.plate, //车牌
            dataType: 9, //数据类型(1:设备离线，2:设备故障，3:天气监控，4:工时监控，5:保单脱保，6：设备出险)
            interveneWay: "确认无风险", //处理方式(确认无风险、误报，风险点)
            alarmId: data.id, //告警id
            alarmType: data.alarmType, //告警类型
            alarmTime: data.timeBegin, //告警时间
            remark: "", //处理内容
            createUserId: that.$store.state.userInfo.userId, //处理用户id
            createUser: that.$store.state.userInfo.name, //处理用户名
            projectName: that.conditionObj.projectName, //项目名称
            projectIds: that.conditionObj.projectId, //项目id
            customName: that.conditionObj.customName, //客户名称
          })
            .then((res) => {
              //instance.confirmButtonLoading = false;
              this.$message.success("处理成功");
              this.$emit("changeList");
              //done();
            })
            .catch((err) => {
              //instance.confirmButtonLoading = false;
              this.$message.success("处理失败");
              //done();
            });
          // this.$msgbox({
          //   title: '',
          //   message: h("div", null, [
          //     h(
          //       "img",
          //       {
          //         style:
          //           "display: block;margin: 0 auto 10px;width:60px;height:60px;",
          //         attrs: {
          //           src: require("@/assets/images/newRiskControl/cl-gj-icon.png"),
          //           alt: "Alternative Text",
          //         },
          //       },
          //       null
          //     ),
          //     h("div", { style:'font-weight: bold;font-size: 16px;color: #333333;text-align: center;margin-bottom: 24px;' }, "提示 "),
          //     h("div", { style:'font-size: 14px;color: #333333;text-align: center;margin-bottom: 24px;' }, "此警告确认无风险？"),
          //   ]),
          //   showCancelButton: true,
          //   confirmButtonText: "确定",
          //   cancelButtonText: "取消",
          //   beforeClose: (action, instance, done) => {
          //     if (action === "confirm") {
          //       instance.confirmButtonLoading = true;
          //       instance.confirmButtonText = "执行中...";
          //       riskHandle(
          //         {
          //           "vehicleId": data.vehicleId, //车辆id
          //           "plate": data.plate, //车牌
          //           "dataType": 9, //数据类型(1:设备离线，2:设备故障，3:天气监控，4:工时监控，5:保单脱保，6：设备出险)
          //           "interveneWay": "确认无风险", //处理方式(确认无风险、误报，风险点)
          //           "alarmId": data.id, //告警id
          //           "alarmType": data.alarmType, //告警类型
          //           "alarmTime": data.timeBegin, //告警时间
          //           "remark": "", //处理内容
          //           "createUserId": that.$store.state.userInfo.userId, //处理用户id
          //           "createUser": that.$store.state.userInfo.name, //处理用户名
          //           "projectName": that.conditionObj.projectName, //项目名称
          //           "projectIds": that.conditionObj.projectId, //项目id
          //           "customName": that.conditionObj.customName //客户名称
          //           }
          //       ).then((res) => {
          //         instance.confirmButtonLoading = false;
          //         this.$message.success('处理成功');
          //         this.$emit('changeList');
          //         done();
          //       }).catch((err) => {
          //         instance.confirmButtonLoading = false;
          //         this.$message.success('处理失败');
          //         done();
          //       });
          //       // setTimeout(() => {
          //       //   done();
          //       //   setTimeout(() => {
          //       //     instance.confirmButtonLoading = false;
          //       //   }, 300);
          //       // }, 3000);
          //     } else {
          //       done();
          //     }
          //   },
          // }).then((action) => {
          // });
          break;
        case "2":
          riskHandle({
            vehicleId: data.vehicleId, //车辆id
            plate: data.plate, //车牌
            dataType: 9, //数据类型(1:设备离线，2:设备故障，3:天气监控，4:工时监控，5:保单脱保，6：设备出险)
            interveneWay: "误报", //处理方式(确认无风险、误报，风险点)
            alarmId: data.id, //告警id
            alarmType: data.alarmType, //告警类型
            alarmTime: data.timeBegin, //告警时间
            remark: "", //处理内容
            createUserId: this.$store.state.userInfo.userId, //处理用户id
            createUser: this.$store.state.userInfo.name, //处理用户名
            projectName: this.conditionObj.projectName, //项目名称
            projectIds: this.conditionObj.projectId, //项目id
            customName: this.conditionObj.customName, //客户名称
          })
            .then((res) => {
              //instance.confirmButtonLoading = false;
              this.$message.success("处理成功");
              this.$emit("changeList");
              //done();
            })
            .catch((err) => {
              //instance.confirmButtonLoading = false;
              this.$message.error("处理失败");
              //done();
            });
          // this.$msgbox({
          //   title: '',
          //   message: h("div", null, [
          //     h(
          //       "img",
          //       {
          //         style:
          //           "display: block;margin: 0 auto 10px;width:60px;height:60px;",
          //         attrs: {
          //           src: require("@/assets/images/newRiskControl/cl-gj-icon.png"),
          //           alt: "Alternative Text",
          //         },
          //       },
          //       null
          //     ),
          //     h("div", { style:'font-weight: bold;font-size: 16px;color: #333333;text-align: center;margin-bottom: 24px;' }, "提示 "),
          //     h("div", { style:'font-size: 14px;color: #333333;text-align: center;margin-bottom: 24px;' }, "此告警是否误报？"),
          //   ]),
          //   showCancelButton: true,
          //   confirmButtonText: "确定",
          //   cancelButtonText: "取消",
          //   beforeClose: (action, instance, done) => {
          //     if (action === "confirm") {
          //       instance.confirmButtonLoading = true;
          //       instance.confirmButtonText = "执行中...";
          //       riskHandle(
          //         {
          //           "vehicleId": data.vehicleId, //车辆id
          //           "plate": data.plate, //车牌
          //           "dataType": 9, //数据类型(1:设备离线，2:设备故障，3:天气监控，4:工时监控，5:保单脱保，6：设备出险)
          //           "interveneWay": "误报", //处理方式(确认无风险、误报，风险点)
          //           "alarmId": data.id, //告警id
          //           "alarmType": data.alarmType, //告警类型
          //           "alarmTime": data.timeBegin, //告警时间
          //           "remark": "", //处理内容
          //           "createUserId": this.$store.state.userInfo.userId, //处理用户id
          //           "createUser": this.$store.state.userInfo.name, //处理用户名
          //           "projectName": this.conditionObj.projectName, //项目名称
          //           "projectIds": this.conditionObj.projectId, //项目id
          //           "customName": this.conditionObj.customName //客户名称
          //           }
          //       ).then((res) => {
          //         instance.confirmButtonLoading = false;
          //         this.$message.success('处理成功');
          //         this.$emit('changeList');
          //         done();
          //       }).catch((err) => {
          //         instance.confirmButtonLoading = false;
          //         this.$message.error('处理失败');
          //         done();
          //       });
          //     } else {
          //       done();
          //     }
          //   },
          // }).then((action) => {
          // });
          break;
        case "3":
          this.isDispose = true;
          this.alarmData = data;
          // this.$emit('changeDialog', 'isDispose', this.currentVehicle, this.equipmentType)
          break;
        case "4":
          // this.isDispose = true;
          this.$emit(
            "changeDialog",
            "isDispose",
            this.currentVehicle,
            this.equipmentType
          );
          break;
      }
    },
  },
};
</script>

<style lang="scss">
.policy-import-dialog {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  .el-dialog__header {
    font-size: 18px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    background: #4278c9;
    .el-dialog__title {
      color: #fff;
    }
    .el-dialog__headerbtn {
      top: 11px;
      .el-dialog__close {
        color: #fff;
        font-weight: bold;
      }
    }
  }
  .el-dialog__body {
    padding: 24px;
    padding-top: 14px;
  }
  .statistical-box {
    &-title {
      &-text {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        color: #333333;
      }
      &-status {
        padding: 4px 12px;
        font-size: 12px;
        font-weight: 400;
        color: #c94242;
        margin-left: 8px;
        background: rgba(201, 66, 66, 0.3);
        border-radius: 16px;
        border: 1px solid #c94242;
      }
      &-status2 {
        color: #00bc0d;
        background: rgba(0, 188, 13, 0.3);
        border: 1px solid #00bc0d;
      }
      &-time {
        color: #cccccc;
        font-size: 14px;
      }
    }
    &-content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 24px;
      > div {
        width: 45%;
        font-size: 14px;
        color: #333333;
        margin-bottom: 16px;
      }
    }
    &-decoration {
      &-top {
        display: flex;
        justify-content: space-between;
        &-tab {
          display: flex;
          div {
            width: 88px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            font-size: 14px;
            color: #333333;
            cursor: pointer;
            border-radius: 8px 8px 0px 0px;
            border: 1px solid #cccccc;
            border-bottom: none;
          }
          &-current {
            color: #fff !important;
            border: 1px solid #4278c9 !important;
            background: #4278c9;
          }
        }
        &-download {
          display: flex;
          align-items: center;
          cursor: pointer;
          color: #0080ff;
          img {
            width: 14px;
            height: 14px;
          }
          span {
            margin-left: 2px;
            text-decoration: underline;
          }
        }
      }
      &-down {
        // display: flex;
        // flex-wrap: wrap;
        // justify-content: space-around;
        padding: 16px 0;
        height: 202px;
        overflow: auto;
        border-radius: 0px 8px 8px 8px;
        border: 1px solid #4278c9;
        &-content {
          width: 128px;
          height: 80px;
          margin-bottom: 8px;
          margin-left: 15px;
          border-radius: 4px;
        }
        &-box {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
        }
      }
    }
  }
}
</style>
